import './App.css';

export const ChartComponent = () => {

  return (
    <div>

    </div>
  );
};

function Disclaimer() {
  return (
    <header className="App-header">
      <div className='Disclaimer' style={{
        padding: '2vh',
      }}>
        Legal disclaimer:
        <br/>
        <p style={{
          fontSize: 'calc(10px + 1vmin)',
        }}>
          The materials provided herein are for the general information
          use. isthisabearmarket.com
          is not authorized and does not intend to provide legal or other professional
          advice or opinions of any kind. You should not act, or refrain from acting,
          based solely upon the materials provided herein and isthisabearmarket.com
          advises you to obtain legal or other professional advice from your
          lawyers and other professional providers.  While isthisabearmarket.com
          has used its reasonable efforts to provide you with accurate materials, these
          materials are summary and general in nature and may change without notice at any
          time. These materials provided should be verified against official sources and
          have no evidentiary value. Your use of these materials is entirely at your own
          risk. In no event shall isthisabearmarket.com, its officers, directors,
          partners, agents, brokers, associates, affiliates, independent contractors or
          employees be liable for any loss, cost or damages whatsoever (including, without
          limitation, damages for harm to business, loss of profits, programs or data,
          interruption of activities or any other pecuniary or economic loss) whether
          direct, indirect, incidental, punitive, special, exemplary, consequential or
          otherwise arising out of any use or misuse of, or any defects, inaccuracies,
          errors or omissions in these materials, even if isthisabearmarket.com
          has been advised of the possibility of damages or if such damages are
          reasonably foreseeable.
        </p>
        <br/>
        TLDR: This website is not financial advice!
      </div>
    </header>
  );
}

export default Disclaimer;

import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import CSS from 'csstype';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { Link } from 'react-router-dom';

interface BearData {
  market_health: {
    bear_market: string,
  },
  data: {
    close: number,
    time: number
  }[]
}

const colors = {
  red: '#f50000',
  green: '#40c9a2'
}

export const ChartComponent = () => {

  const chartContainerRef: any = useRef();
  const [chart, setChart] = useState<Chart>();
  const [chartData, setChartData] = useState<{ x: number, y: number }[]>();
  const [bearMarket, setBearMarket] = useState<boolean>();

  useEffect(() => {
    const chart = new Chart(chartContainerRef.current, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          data: [],
          fill: true,
          yAxisID: 'y',
          backgroundColor: colors.red,
          parsing: false,
          borderWidth: 0
        }]
      },
      options: {
        elements: {
          point: {
            radius: 0
          }
        },
        animation: {
          duration: 0
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            display: false,
            type: 'timeseries',
          },
          y: {
            display: false,
            type: 'linear',
          },
        },
        datasets: {
          line: {
            clip: 0,
            spanGaps: false,
            borderWidth: 50,
          }
        }
      },
    });

    setChart(chart);

    return () => {
      chart?.destroy();
      setChart(undefined);
    };
  }, []);

  useEffect(
    () => {
      fetch('/dist')
        .then(res => res.json() as Promise<BearData>)
        .then(
          (data) => {
            if (data.market_health.bear_market === "Bear") {
              setBearMarket(true);
            } else {
              setBearMarket(false);
            }

            let graph = [];

            for (const bar of data.data) {
              graph.push({
                x: bar.time,
                y: bar.close
              });
            }

            setChartData(graph);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error)
          }
        )
    },
    []
  );

  useEffect(() => {
    if (chart && chartData) {
      chart.data.datasets[0].data = chartData;
      chart.update();
    }
  }, [chartData, chart]);

  useEffect(() => {
    if (chart && bearMarket !== undefined) {

      chart.data.datasets[0].backgroundColor = bearMarket ? colors.red : colors.green;

      chart.update();
    }
  }, [chart, bearMarket])

  const divStyleOuter: CSS.Properties = {
    width: '100%',
    height: '50%',
  };

  return (
    <div style={
      {
        width: '100vw',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }
    }>
      <div style={{ padding: 'calc(10px + 5vmin)' }}>
        {bearMarket === true ?

          <div>
            <h1>It's a <span style={{ color: colors.red }}>
              bear
            </span> market</h1>

            All of my savings are being <span style={{ color: colors.red }}>
              incinerated
            </span> 😭
          </div>

          :
          <div>
            {
              bearMarket === false ?
                <h1>It's not a bear market!</h1> :
                <p>Fetching data...</p>
            }
          </div>
        }
        <br />
        <p style={{ fontSize: 'calc(10px + 1vmin)' }}>
          A bear market occurs <a href='https://en.wikipedia.org/wiki/Market_trend#Bear_market'>
            when prices in a market decline by more than 20%
          </a>, and a bear market ends when the <a href='https://en.wikipedia.org/wiki/Market_trend#Bear_market'>market reaches a new all time high</a>
        </p>
        <br/>
        <p style={{ fontSize: 'calc(10px)' }}>
          <Link to="/disclaimer">Legal Disclaimer</Link>
        </p>
      </div>
      <div style={divStyleOuter}>
        <canvas ref={chartContainerRef}></canvas>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <ChartComponent></ChartComponent>
      </header>
    </div>
  );
}

export default App;

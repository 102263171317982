import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Disclaimer from './Disclaimer';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { init, trackPages } from "insights-js"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={
    <React.StrictMode>
      <App />
    </React.StrictMode>} />
    <Route path="/disclaimer" element={
    <React.StrictMode>
      <Disclaimer />
    </React.StrictMode>} />
  </Routes>
  </BrowserRouter>
);

init("skA2ogLHSW6cnxHE")
trackPages();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
